import { Heading, Box, Text, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { BenefitDetails, BenefitSection } from '../../../BenefitSection';
import CanyouLogo from '../../../../assets/canyou-blue-logo.svg';

type Data = {
  smartWorkflows: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  licenceCertificationDashboard: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  qrScan: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  qrPhone: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  digitalLicenceSubmission: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  licenceCertificationAssignment: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  notificationAndReminder: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const BenefitsSection: React.FC = () => {
  const {
    smartWorkflows,
    licenceCertificationDashboard,
    licenceCertificationAssignment,
    notificationAndReminder,
    qrScan,
    qrPhone,
    digitalLicenceSubmission,
  } = useStaticQuery<Data>(
    graphql`
      {
        smartWorkflows: file(
          relativePath: { eq: "screenshots/smart-workflows.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        notificationAndReminder: file(
          relativePath: { eq: "screenshots/notification-and-reminder.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 280, layout: CONSTRAINED, quality: 90)
          }
        }
        licenceCertificationDashboard: file(
          relativePath: {
            eq: "screenshots/licence-certification-dashboard.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        qrScan: file(relativePath: { eq: "features/qr-code-live-test.png" }) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        qrPhone: file(
          relativePath: { eq: "screenshots/qr-code-employee-device.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        digitalLicenceSubmission: file(
          relativePath: { eq: "screenshots/digital-licence-submission.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        licenceCertificationAssignment: file(
          relativePath: {
            eq: "screenshots/licence-certification-assignment.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 280, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );

  const benefitDetails: BenefitDetails[] = [
    {
      heading: 'Smart workflows to collect what is needed',
      subHeading: 'No more paper, emails, sms or spreadsheets',
      body: 'Simply assign team licence and certification requirements and Canyou will collect what is needed.',
      isOrderReversed: true,
      images: [
        {
          gatsbyImageData: smartWorkflows.childImageSharp.gatsbyImageData,
          alt: 'Smart workflows',
        },
      ],
    },
    {
      heading: 'Business compliance dashboard',
      subHeading: 'Real time monitoring',
      body: 'Review your team member status instantly to make sure the team is always ready to work and the business is safe.',
      images: [
        {
          gatsbyImageData:
            licenceCertificationDashboard.childImageSharp.gatsbyImageData,
          alt: 'Licence certification dashboard',
        },
        {
          gatsbyImageData:
            licenceCertificationAssignment.childImageSharp.gatsbyImageData,
          alt: 'Licence certification assignment',
        },
      ],
    },
    {
      heading: 'Automated notifications and reminders',
      subHeading: 'Never let a licence expire ever again',
      body: 'Notifications and reminders get sent to the team when licences are falling due. No more more manual checking of spreadsheets.',
      isOrderReversed: true,
      images: [
        {
          gatsbyImageData:
            notificationAndReminder.childImageSharp.gatsbyImageData,
          alt: 'Notification and reminders',
        },
      ],
    },
    {
      heading: 'Digital submission from the employees device',
      subHeading: 'Conveniently submit whats needed',
      body: 'Digital completion via the employees device with automatic validations.',
      images: [
        {
          gatsbyImageData:
            digitalLicenceSubmission.childImageSharp.gatsbyImageData,
          alt: 'Digital licence submission',
        },
      ],
    },
    {
      heading: 'Easy for everyone',
      subHeading: 'QR-Code access - Passwordless login',
      body: 'No passwords required. Scan a QR code to get started or be invited by the business administrator.',
      isOrderReversed: true,
      images: [
        {
          gatsbyImageData: qrScan.childImageSharp.gatsbyImageData,
          alt: 'QR code for employees',
        },
        {
          gatsbyImageData: qrPhone.childImageSharp.gatsbyImageData,
          alt: 'Scan QR code from employee device',
        },
      ],
    },
  ];

  return (
    <Box
      pb={{ base: '35px', lg: '120px' }}
      pt={{ base: '32px', lg: '42px' }}
      bg="brand.egg"
    >
      <Text fontSize="32px" fontFamily="roboto" textAlign="center" pb="24px">
        The simple and affordable solution
      </Text>
      <Flex justify="center">
        <CanyouLogo width={260} />
      </Flex>
      <Heading
        as="h3"
        fontSize="36px"
        textAlign="center"
        px={{ base: 4, lg: '200px' }}
        pt={{ base: '20px', lg: '32px' }}
        pb={{ base: '55px', lg: '140px' }}
      >
        <Box as="span">Automated </Box>
        <Box as="span" color="brand.blue">
          collection{' '}
        </Box>
        <Box as="span">and</Box>{' '}
        <Box as="span" color="brand.blue">
          monitoring{' '}
        </Box>{' '}
        <Box as="span">of employee </Box>
        <Box as="span" color="brand.blue">
          licences{' '}
        </Box>{' '}
        <Box as="span">and </Box>
        <Box as="span" color="brand.blue">
          certifications{' '}
        </Box>{' '}
        <Box as="span">to ensure the business stays compliant</Box>
      </Heading>
      {benefitDetails.map(
        ({ heading, body, images, subHeading, isOrderReversed }) => (
          <BenefitSection
            key={heading}
            heading={heading}
            body={body}
            images={images}
            subHeading={subHeading}
            isOrderReversed={isOrderReversed}
          />
        ),
      )}
    </Box>
  );
};
