import * as React from 'react';
import { Heading, Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProblemBulletList } from './ProblemBulletList';

type Data = {
  spreadsheet: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const problemsList3: string[] = [
  "You have a spreadsheet for when licences expire but it's hard keeping it up to date",
  'You need to manually check the spreadsheet to check when items need to be actioned',
];

export const ProblemSection3: React.FC = () => {
  const { spreadsheet } = useStaticQuery<Data>(
    graphql`
      {
        spreadsheet: file(relativePath: { eq: "problems/spreadsheet.png" }) {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <>
      <Heading
        as="h2"
        fontSize="40px"
        textAlign="center"
        px={4}
        pt={{ base: '25px', lg: '55px' }}
        pb={{ base: '55px' }}
      >
        <Box as="span">
          ...And try keep that spreadsheet
          <br />
          up to date
        </Box>
      </Heading>
      <Flex
        direction={{
          base: 'column',
        }}
        justify="space-around"
        align="center"
        px={{ base: 0, xl: '130px' }}
        gridGap="6"
      >
        <Box px={2}>
          <GatsbyImage
            alt="spreadsheet"
            image={spreadsheet.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        </Box>
        <ProblemBulletList problemList={problemsList3} />
      </Flex>
    </>
  );
};
