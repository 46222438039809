import * as React from 'react';
import { Heading, Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProblemBulletList } from './ProblemBulletList';

type Data = {
  smsEmailConversation: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const problemList: string[] = [
  "You're constantly going back and forth with email and sms chasing the docs the business needs from the team",
  "You're still unsure which team members hold certain certifications",
  'You manually need to organise team profiles in cloud storage (or worse the filing cabinet)',
  'Onboarding new team members is time consuming',
  'When compliance requirements change you have to make requests to the team one by one',
];

export const ProblemSection2: React.FC = () => {
  const { smsEmailConversation } = useStaticQuery<Data>(
    graphql`
      {
        smsEmailConversation: file(
          relativePath: { eq: "problems/sms-conversation.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 500, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <>
      <Heading
        as="h2"
        fontSize="40px"
        textAlign="center"
        px={4}
        pt={{ base: '45px', lg: '55px' }}
        pb={{ base: '55px' }}
      >
        <Box as="span">
          Which means you fall back to
          <br />
          email, SMS and manual processes...
        </Box>
      </Heading>
      <Flex
        direction={{
          base: 'column-reverse',
          lg: 'row',
        }}
        justify="space-around"
        align="center"
        px={{ base: 0, xl: '130px' }}
        gridGap="6"
      >
        <ProblemBulletList problemList={problemList} />

        <Box>
          <GatsbyImage
            alt="SMS email conversation"
            image={smsEmailConversation.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
