import { Heading, Box, Flex, Icon, Text } from '@chakra-ui/react';
import * as React from 'react';
import { HiOutlineIdentification } from 'react-icons/hi';
import { RiCalendarCheckLine } from 'react-icons/ri';
import { AiOutlineAlert } from 'react-icons/ai';
import { BsCheck2Circle } from 'react-icons/bs';
import { IconType } from 'react-icons';

export const ProblemSolutionSummary: React.FC = () => {
  const solutionDetails: SolutionTileProps[] = [
    {
      text: 'Collect and save copies of employee licences and certificates',
      icon: HiOutlineIdentification,
    },
    {
      text: 'Track licence expiry dates',
      icon: RiCalendarCheckLine,
    },
    {
      text: 'Reduce the risks associated with business compliance',
      icon: AiOutlineAlert,
    },
    {
      text: 'Ensure every team member is qualified to do their job',
      icon: BsCheck2Circle,
    },
  ];

  return (
    <Box pb={{ base: '35px', lg: '40px' }} bg="brand.egg">
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        px={4}
        pt={{ base: '25px', lg: '115px' }}
        pb={{ base: '55px' }}
      >
        <Box as="span" color="brand.blue">
          Managing{' '}
        </Box>
        <Box as="span">
          your teams&apos; licences
          <br />
          and certifications shouldn&apos;t be so hard
        </Box>
      </Heading>
      <Heading
        color="brand.blue"
        as="h3"
        fontFamily="roboto"
        fontWeight="600"
        fontSize="36px"
        textAlign={{ base: 'center' }}
        pb="25px"
      >
        If you are looking for a way to
      </Heading>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        wrap="wrap"
        justify="center"
        align="center"
        gridGap="10"
        px={{ base: 0, xl: '130px' }}
      >
        {solutionDetails.map(({ text, icon }) => (
          <SolutionTile key={text} icon={icon} text={text} />
        ))}
      </Flex>
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        px={4}
        pt={{ base: '25px', lg: '115px' }}
        pb={{ base: '55px' }}
      >
        <Box as="span">With </Box>
        <Box as="span" color="brand.blue">
          Canyou{' '}
        </Box>
        <Box as="span">you can</Box>
      </Heading>
    </Box>
  );
};

type SolutionTileProps = { text: string; icon: IconType };

const SolutionTile: React.FC<SolutionTileProps> = ({ icon, text }) => (
  <Flex
    direction="column"
    bgColor="brand.white"
    boxShadow="0px 24px 32px #000F2F14"
    rounded="14px"
    align="center"
    maxW="220px"
    minH="260px"
    px="30px"
    pt="50px"
    pb="40px"
  >
    <Icon color="brand.blue" w={50} h={50} as={icon} mb={5} />
    <Text textAlign="center">{text}</Text>
  </Flex>
);
