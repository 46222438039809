import * as React from 'react';
import { Heading, Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProblemBulletList } from './ProblemBulletList';

type Data = {
  ladyCheckLicences: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const problemList: string[] = [
  'HR apps can be complex, expensive or a combination of both',
  'You need to spend hours learning how to use it',
  'Setup time can be weeks if not months',
  'You only want certain features but have to pay for the whole package',
];

export const ProblemSection1: React.FC = () => {
  const { ladyCheckLicences } = useStaticQuery<Data>(
    graphql`
      {
        ladyCheckLicences: file(
          relativePath: { eq: "problems/lady-checking-licences.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <>
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        px={4}
        pb={{ base: '55px' }}
      >
        <Box as="span" color="brand.blue">
          Searching{' '}
        </Box>
        <Box as="span">
          for the right HR system
          <br />
          is a daunting task
        </Box>
      </Heading>
      <Flex
        direction={{
          base: 'column',
          lg: 'row',
        }}
        justify="space-around"
        align="center"
        px={{ base: 0, xl: '130px' }}
        gridGap="6"
      >
        <Box>
          <GatsbyImage
            alt="Lady checking licences"
            image={ladyCheckLicences.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        </Box>
        <ProblemBulletList problemList={problemList} />
      </Flex>
    </>
  );
};
