import * as React from 'react';
import { Heading, Box } from '@chakra-ui/react';
import { ProblemSection1 } from './ProblemSection1';
import { ProblemSection2 } from './ProblemSection2';
import { ProblemSection3 } from './ProblemSection3';

export const ProblemSection: React.FC = () => {
  return (
    <Box py={{ base: '35px', lg: '90px' }}>
      <Heading
        as="h3"
        fontFamily="roboto"
        fontWeight="500"
        fontSize={{ base: '24px', md: '36px' }}
        textAlign={{ base: 'center' }}
        pb="12px"
        px={4}
      >
        Does this sound familiar?
      </Heading>
      <ProblemSection1 />
      <ProblemSection2 />
      <ProblemSection3 />
    </Box>
  );
};
